(function ($, window, document, undefined) {

    function googAnal(id) {
        ga('send', 'event', id, 'click');
    }

    /*
     * Test object init
     */

    var test = {

        modal: null,

        go_home: function () {

            $('.site-tests .test-nav-container').css('display', 'block');
            $('.site-tests .test-container').html('');
        },

        bind_events: function () {

            $('body').on('amxmodal:closed', function () {

                $('.close-test').addClass('hidden');
                $('.message-test-warning').addClass('hidden');
                test.go_home();
            });
        },

        init: function () {

            $(document).ready(function () {

                if ($('.site-tests'.length)) {

                    test.modal = new amxModal();
                    test.bind_events();
                    test.nav.init();
                }
            });
        }
    };

    /*
     * Test component nav
     */

    test.nav = {

        bind_events: function () {

            $('*[data-action="open-test"]').each(function () {

                $(this).on('click', function () {

                    var id = $(this).data('test');
                    test.ajax.load(id);
                });
            });

            $('*[data-action="close-test"]').each(function () {

                $(this).on('click', function () {

                    $('.close-test').addClass('hidden');
                    $('.message-test-warning').addClass('hidden');
                    test.go_home();
                });
            });

        },

        init: function () {
            test.nav.bind_events();
        }
    };

    /*
     * Test component ajax
     */

    test.ajax = {

        load: function (id) {

            $.ajax({

                data: {
                    action: 'medix_get_test',
                    id: id
                },

                url: wp_data.ajax_url,
                type: 'POST',

                success: function (data) {

                    $('.close-test').removeClass('hidden');
                    $('.message-test-warning').removeClass('hidden');
                    $('.site-tests .test-nav-container').css('display', 'none');
                    $('.site-tests .test-container').html(data);

                    switch (id) {
                    case 1:
                        googAnal('T01-Q01');
                        test.item_01.init();
                        break;
                    case 2:
                        googAnal('T02-Q01');
                        test.item_02.init();
                        break;
                    case 3:
                        googAnal('T03-Q01');
                        test.item_03.init();
                        break;
                    case 4:
                        googAnal('T04-Q01');
                        test.item_04.init();
                        break;
                    case 5:
                        googAnal('T05-Q01');
                        test.item_05.init();
                        break;
                    case 6:
                        googAnal('T06-Q01');
                        test.item_06.init();
                        break;
                    case 7:
                        googAnal('T07-Q01');
                        test.item_07.init();
                        break;
                    case 8:
                        googAnal('T08-Q01');
                        test.item_08.init();
                        break;
                    }
                }
            });
        }
    };

    /*
     * Test - 01
     */

    test.item_01 = {

        item_logic: function () {

            var a = 0,
                boc = 0,
                c = 0;
            $('#T01-Q01').show();

            //Question 1

            $("#T01-Q01-A01").click(function () {
                googAnal('T01-Q02');
                a++;
                $('.hidden').hide();
                $('#T01-Q02').show();
            });

            $("#T01-Q01-A02").click(function () {
                googAnal('T01-Q02');
                boc++;
                $('.hidden').hide();
                $('#T01-Q02').show();
            });

            $("#T01-Q01-A03").click(function () {
                googAnal('T01-Q02');
                c++;
                $('.hidden').hide();
                $('#T01-Q02').show();
            });

            //Question 2

            $("#T01-Q02-A01").click(function () {
                googAnal('T01-Q03');
                a++;
                $('.hidden').hide();
                $('#T01-Q03').show();
            });

            $("#T01-Q02-A02").click(function () {
                googAnal('T01-Q03');
                boc++;
                $('.hidden').hide();
                $('#T01-Q03').show();
            });

            $("#T01-Q02-A03").click(function () {
                googAnal('T01-Q03');
                c++;
                $('.hidden').hide();
                $('#T01-Q03').show();
            });

            //Question 3

            $("#T01-Q03-A01").click(function () {
                googAnal('T01-Q04');
                a++;
                $('.hidden').hide();
                $('#T01-Q04').show();
            });

            $("#T01-Q03-A02").click(function () {
                googAnal('T01-Q04');
                boc++;
                $('.hidden').hide();
                $('#T01-Q04').show();
            });

            $("#T01-Q03-A03").click(function () {
                googAnal('T01-Q04');
                c++;
                $('.hidden').hide();
                $('#T01-Q04').show();
            });

            //Question 4

            $("#T01-Q04-A01").click(function () {
                googAnal('T01-Q05');
                a++;
                $('.hidden').hide();
                $('#T01-Q05').show();
            });

            $("#T01-Q04-A02").click(function () {
                googAnal('T01-Q05');
                boc++;
                $('.hidden').hide();
                $('#T01-Q05').show();
            });

            $("#T01-Q04-A03").click(function () {
                googAnal('T01-Q05');
                c++;
                $('.hidden').hide();
                $('#T01-Q05').show();
            });

            //Question 5

            $("#T01-Q05-A01").click(function () {
                googAnal('T01-Q06');
                a++;
                $('.hidden').hide();
                $('#T01-Q06').show();
            });

            $("#T01-Q05-A02").click(function () {
                googAnal('T01-Q06');
                boc++;
                $('.hidden').hide();
                $('#T01-Q06').show();
            });

            $("#T01-Q05-A03").click(function () {
                googAnal('T01-Q06');
                c++;
                $('.hidden').hide();
                $('#T01-Q06').show();
            });

            //Question 6

            $("#T01-Q06-A01").click(function () {
                googAnal('T01-Q07');
                a++;
                $('.hidden').hide();
                $('#T01-Q07').show();
            });

            $("#T01-Q06-A02").click(function () {
                googAnal('T01-Q07');
                boc++;
                $('.hidden').hide();
                $('#T01-Q07').show();
            });

            $("#T01-Q06-A03").click(function () {
                googAnal('T01-Q07');
                c++;
                $('.hidden').hide();
                $('#T01-Q07').show();
            });

            //Question 7

            $("#T01-Q07-A01").click(function () {
                googAnal('T01-Q08');
                a++;
                $('.hidden').hide();
                $('#T01-Q08').show();
            });

            $("#T01-Q07-A02").click(function () {
                googAnal('T01-Q08');
                boc++;
                $('.hidden').hide();
                $('#T01-Q08').show();
            });

            $("#T01-Q07-A03").click(function () {
                googAnal('T01-Q08');
                c++;
                $('.hidden').hide();
                $('#T01-Q08').show();
            });

            //Question 8

            $("#T01-Q08-A01").click(function () {
                googAnal('T01-Q09');
                a++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q09').show();
            });

            $("#T01-Q08-A02").click(function () {
                googAnal('T01-Q09');
                boc++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q09').show();
            });

            $("#T01-Q08-A03").click(function () {
                googAnal('T01-Q09');
                c++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q09').show();
            });

            //Question 9

            $("#T01-Q09-A01").click(function () {
                googAnal('T01-Q10');
                a++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q10').show();
            });

            $("#T01-Q09-A02").click(function () {
                googAnal('T01-Q10');
                boc++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q10').show();
            });

            $("#T01-Q09-A03").click(function () {
                googAnal('T01-Q10');
                c++;
                $('.hidden').hide();
                $('#main').hide();
                $('#T01-Q10').show();
            });

            //Question 10

            $("#T01-Q10-A01").click(function () {
                googAnal('T01-OK');
                a++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T01-Q10-A02").click(function () {
                googAnal('T01-OK');
                boc++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T01-Q10-A03").click(function () {
                googAnal('T01-OK');
                c++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                if (a > boc && a > c) {
                    $('#result #result-b').remove();
                    $('#result #result-c').remove();
                } else {
                    if (boc > c) {
                        $('#result #result-a').remove();
                        $('#result #result-c').remove();
                    } else {
                        $('#result #result-a').remove();
                        $('#result #result-b').remove();
                    }
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            }
        },

        init: function () {
            test.item_01.item_logic();
        }
    };

    /*
     * Test - 02
     */

    test.item_02 = {

        logic: function () {

            var aob = 0,
                cod = 0;
            $('#T02-Q01').show();

            //Question 1

            $("#T02-Q01-A01").click(function () {
                googAnal('T02-Q02');
                aob++;
                $('.hidden').hide();
                $('#T02-Q02').show();
            });

            $("#T02-Q01-A02").click(function () {
                googAnal('T02-Q02');
                aob++;
                $('.hidden').hide();
                $('#T02-Q02').show();
            });

            $("#T02-Q01-A03").click(function () {
                googAnal('T02-Q02');
                cod++;
                $('.hidden').hide();
                $('#T02-Q02').show();
            });

            $("#T02-Q01-A04").click(function () {
                googAnal('T02-Q02');
                cod++;
                $('.hidden').hide();
                $('#T02-Q02').show();
            });

            //Question 2

            $("#T02-Q02-A01").click(function () {
                googAnal('T02-Q03');
                aob++;
                $('.hidden').hide();
                $('#T02-Q03').show();
            });

            $("#T02-Q02-A02").click(function () {
                googAnal('T02-Q03');
                aob++;
                $('.hidden').hide();
                $('#T02-Q03').show();
            });

            $("#T02-Q02-A03").click(function () {
                googAnal('T02-Q03');
                cod++;
                $('.hidden').hide();
                $('#T02-Q03').show();
            });

            $("#T02-Q02-A04").click(function () {
                googAnal('T02-Q03');
                cod++;
                $('.hidden').hide();
                $('#T02-Q03').show();
            });

            //Question 3

            $("#T02-Q03-A01").click(function () {
                googAnal('T02-Q04');
                aob++;
                $('.hidden').hide();
                $('#T02-Q04').show();
            });

            $("#T02-Q03-A02").click(function () {
                googAnal('T02-Q04');
                aob++;
                $('.hidden').hide();
                $('#T02-Q04').show();
            });

            $("#T02-Q03-A03").click(function () {
                googAnal('T02-Q04');
                cod++;
                $('.hidden').hide();
                $('#T02-Q04').show();
            });

            $("#T02-Q03-A04").click(function () {
                googAnal('T02-Q04');
                cod++;
                $('.hidden').hide();
                $('#T02-Q04').show();
            });

            //Question 4

            $("#T02-Q04-A01").click(function () {
                googAnal('T02-Q05');
                aob++;
                $('.hidden').hide();
                $('#T02-Q05').show();
            });

            $("#T02-Q04-A02").click(function () {
                googAnal('T02-Q05');
                cod++;
                $('.hidden').hide();
                $('#T02-Q05').show();
            });

            //Question 5

            $("#T02-Q05-A01").click(function () {
                googAnal('T02-OK');
                aob++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T02-Q05-A02").click(function () {
                googAnal('T02-OK');
                cod++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                if (aob > cod) {
                    $('#result #res_cod').remove();
                } else {
                    $('#result #res_aob').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };
        },

        init: function () {
            test.item_02.logic();
        }
    };

    /*
     * Test - 03
     */

    test.item_03 = {

        logic: function () {
            $('#T03-Q01').show();

            //Question 1

            $("#T03-Q01-A01").click(function () {
                googAnal('T03-02');
                $('.hidden').hide();
                $('#T03-Q02').show();
            });

            $("#T03-Q01-A02").click(function () {
                googAnal('T03-02');
                $('.hidden').hide();
                $('#T03-Q02').show();
            });

            $("#T03-Q01-A03").click(function () {
                googAnal('T03-02');
                $('.hidden').hide();
                $('#T03-Q02').show();
            });

            $("#T03-Q01-A04").click(function () {
                googAnal('T03-02');
                $('.hidden').hide();
                $('#T03-Q02').show();
            });

            //Question 2

            $("#T03-Q02-A01").click(function () {
                googAnal('T03-03');
                $('.hidden').hide();
                $('#T03-Q03').show();
            });

            $("#T03-Q02-A02").click(function () {
                googAnal('T03-03');
                $('.hidden').hide();
                $('#T03-Q03').show();
            });

            $("#T03-Q02-A03").click(function () {
                googAnal('T03-03');
                $('.hidden').hide();
                $('#T03-Q03').show();
            });

            $("#T03-Q02-A04").click(function () {
                googAnal('T03-03');
                $('.hidden').hide();
                $('#T03-Q03').show();
            });

            //Question 3

            $("#T03-Q03-A01").click(function () {
                googAnal('T03-04');
                $('.hidden').hide();
                $('#T03-Q04').show();
            });

            $("#T03-Q03-A02").click(function () {
                googAnal('T03-04');
                $('.hidden').hide();
                $('#T03-Q04').show();
            });

            $("#T03-Q03-A03").click(function () {
                googAnal('T03-04');
                $('.hidden').hide();
                $('#T03-Q04').show();
            });

            $("#T03-Q03-A04").click(function () {
                googAnal('T03-04');
                $('.hidden').hide();
                $('#T03-Q04').show();
            });

            //Question 4

            $("#T03-Q04-A01").click(function () {
                googAnal('T03-05');
                $('.hidden').hide();
                $('#T03-Q05').show();
            });
            $("#T03-Q04-A02").click(function () {
                googAnal('T03-05');
                $('.hidden').hide();
                $('#T03-Q05').show();
            });
            $("#T03-Q04-A03").click(function () {
                googAnal('T03-05');
                $('.hidden').hide();
                $('#T03-Q05').show();
            });
            $("#T03-Q04-A04").click(function () {
                googAnal('T03-05');
                $('.hidden').hide();
                $('#T03-Q05').show();
            });

            //Question 5

            $("#T03-Q05-A01").click(function () {
                googAnal('T03-OK');
                $('.hidden').hide();
                $('#main').hide();
                check();
            });
            $("#T03-Q05-A02").click(function () {
                googAnal('T03-OK');
                $('.hidden').hide();
                $('#main').hide();
                check();
            });
            $("#T03-Q05-A03").click(function () {
                googAnal('T03-OK');
                $('.hidden').hide();
                $('#main').hide();
                check();
            });
            $("#T03-Q05-A04").click(function () {
                googAnal('T03-OK');
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };
        },

        init: function () {
            test.item_03.logic();
        }
    };

    /*
     * Test - 04
     */

    test.item_04 = {

        logic: function () {
            var aboc = 0;
            var d = 0;

            $('#T04-Q01').show();

            //Question 1

            $("#T04-Q01-A01").click(function () {
                googAnal('T04-02');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q02').show();
            });

            $("#T04-Q01-A02").click(function () {
                googAnal('T04-02');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q02').show();
            });

            $("#T04-Q01-A03").click(function () {
                googAnal('T04-02');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q02').show();
            });

            $("#T04-Q01-A04").click(function () {
                googAnal('T04-02');
                d++;
                $('.hidden').hide();
                $('#T04-Q02').show();
            });

            //Question 2

            $("#T04-Q02-A01").click(function () {
                googAnal('T04-03');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q03').show();
            });

            $("#T04-Q02-A02").click(function () {
                googAnal('T04-03');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q03').show();
            });

            $("#T04-Q02-A03").click(function () {
                googAnal('T04-03');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q03').show();
            });

            $("#T04-Q02-A04").click(function () {
                googAnal('T04-03');
                d++;
                $('.hidden').hide();
                $('#T04-Q03').show();
            });

            //Question 3

            $("#T04-Q03-A01").click(function () {
                googAnal('T04-04');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q04').show();
            });

            $("#T04-Q03-A02").click(function () {
                googAnal('T04-04');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q04').show();
            });

            $("#T04-Q03-A03").click(function () {
                googAnal('T04-04');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q04').show();
            });

            $("#T04-Q03-A04").click(function () {
                googAnal('T04-04');
                d++;
                $('.hidden').hide();
                $('#T04-Q04').show();
            });

            //Question 4

            $("#T04-Q04-A01").click(function () {
                googAnal('T04-05');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q05').show();
            });

            $("#T04-Q04-A02").click(function () {
                googAnal('T04-05');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q05').show();

            });

            $("#T04-Q04-A03").click(function () {
                googAnal('T04-05');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q05').show();
            });

            $("#T04-Q04-A04").click(function () {
                googAnal('T04-05');
                d++;
                $('.hidden').hide();
                $('#T04-Q05').show();
            });

            //Question 5

            $("#T04-Q05-A01").click(function () {
                googAnal('T04-06');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q06').show();
            });

            $("#T04-Q05-A02").click(function () {
                googAnal('T04-06');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q06').show();
            });

            $("#T04-Q05-A03").click(function () {
                googAnal('T04-06');
                aboc++;
                $('.hidden').hide();
                $('#T04-Q06').show();
            });

            $("#T04-Q05-A04").click(function () {
                googAnal('T04-06');
                d++;
                $('.hidden').hide();
                $('#T04-Q06').show();
            });

            //Question 6

            $("#T04-Q06-A01").click(function () {
                googAnal('T04-OK');
                aboc++;
                $('.hidden').hide();
                $('#main').hide();
                check();

            });

            $("#T04-Q06-A02").click(function () {
                googAnal('T04-OK');
                aboc++;
                $('.hidden').hide();
                $('#main').hide();
                check();

            });

            $("#T04-Q06-A03").click(function () {
                googAnal('T04-OK');
                aboc++;
                $('.hidden').hide();
                $('#main').hide();
                check();

            });

            $("#T04-Q06-A04").click(function () {
                googAnal('T04-OK');
                d++;
                $('.hidden').hide();
                $('#main').hide();
                check();

            });

            function check() {
                if (aboc > d) {
                    $('#result #res_d').remove();
                } else {
                    $('#result #res_aboc').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };
        },

        init: function () {
            test.item_04.logic();
        }
    };

    /*
     * Test - 05
     */

    test.item_05 = {

        logic: function () {
            var aob = 0,
                cod = 0;

            $('#T05-Q01').show();

            //Question 1

            $("#T05-Q01-A01").click(function () {
                googAnal('T05-02');
                aob++;
                $('.hidden').hide();
                $('#T05-Q02').show();
            });

            $("#T05-Q01-A02").click(function () {
                googAnal('T05-02');
                aob++;
                $('.hidden').hide();

                $('#T05-Q02').show();
            });

            $("#T05-Q01-A03").click(function () {
                googAnal('T05-02');
                cod++;
                $('.hidden').hide();
                $('#T05-Q02').show();
            });

            $("#T05-Q01-A04").click(function () {
                googAnal('T05-02');
                cod++;
                $('.hidden').hide();
                $('#T05-Q02').show();
            });

            //Question 2

            $("#T05-Q02-A01").click(function () {
                googAnal('T05-03');
                aob++;
                $('.hidden').hide();
                $('#T05-Q03').show();
            });

            $("#T05-Q02-A02").click(function () {
                googAnal('T05-03');
                aob++;
                $('.hidden').hide();
                $('#T05-Q03').show();
            });

            $("#T05-Q02-A03").click(function () {
                googAnal('T05-03');
                cod++;
                $('.hidden').hide();
                $('#T05-Q03').show();
            });

            $("#T05-Q02-A04").click(function () {
                googAnal('T05-03');
                cod++;
                $('.hidden').hide();
                $('#T05-Q03').show();
            });

            //Question 3

            $("#T05-Q03-A01").click(function () {
                googAnal('T05-04');
                aob++;
                $('.hidden').hide();
                $('#T05-Q04').show();
            });

            $("#T05-Q03-A02").click(function () {
                googAnal('T05-04');
                aob++;
                $('.hidden').hide();
                $('#T05-Q04').show();
            });

            $("#T05-Q03-A03").click(function () {
                googAnal('T05-04');
                cod++;
                $('.hidden').hide();
                $('#T05-Q04').show();
            });

            $("#T05-Q03-A04").click(function () {
                googAnal('T05-04');
                cod++;
                $('.hidden').hide();
                $('#T05-Q04').show();
            });

            //Question 4

            $("#T05-Q04-A01").click(function () {
                googAnal('T05-05');
                aob++;
                $('.hidden').hide();
                $('#T05-Q05').show();
            });

            $("#T05-Q04-A02").click(function () {
                googAnal('T05-05');
                aob++;
                $('.hidden').hide();
                $('#T05-Q05').show();
            });

            $("#T05-Q04-A03").click(function () {
                googAnal('T05-05');
                cod++;
                $('.hidden').hide();
                $('#T05-Q05').show();
            });

            $("#T05-Q04-A04").click(function () {
                googAnal('T05-05');
                cod++;
                $('.hidden').hide();
                $('#T05-Q05').show();
            });

            //Question 5

            $("#T05-Q05-A01").click(function () {
                googAnal('T05-06');
                aob++;
                $('.hidden').hide();
                $('#T05-Q06').show();
            });

            $("#T05-Q05-A02").click(function () {
                googAnal('T05-06');
                aob++;
                $('.hidden').hide();
                $('#T05-Q06').show();
            });

            $("#T05-Q05-A03").click(function () {
                googAnal('T05-06');
                cod++;
                $('.hidden').hide();
                $('#T05-Q06').show();
            });

            $("#T05-Q05-A04").click(function () {
                googAnal('T05-06');
                cod++;
                $('.hidden').hide();
                $('#T05-Q06').show();
            });

            //Question 6

            $("#T05-Q06-A01").click(function () {
                googAnal('T05-OK');
                aob++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T05-Q06-A02").click(function () {
                googAnal('T05-OK');
                aob++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T05-Q06-A03").click(function () {
                googAnal('T05-OK');
                cod++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T05-Q06-A04").click(function () {
                googAnal('T05-OK');
                cod++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                if (aob > cod) {
                    $('#result #res_cod').remove();
                } else {
                    $('#result #res_aob').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };

        },

        init: function () {
            test.item_05.logic();
        }
    };

    /*
     * Test - 06
     */

    test.item_06 = {

        logic: function () {
            var ab = 0,
                bc = 0,
                d = 0,
                bcd = 0,
                preguntaCinco = false;

            $('#T06-Q01').show();

            //Question 1

            $("#T06-Q01-A01").click(function () {
                googAnal('T06-02');
                ab++;
                $('.hidden').hide();
                $('#T06-Q02').show();
            });

            $("#T06-Q01-A02").click(function () {
                googAnal('T06-02');
                ab++;
                bc++;
                $('.hidden').hide();

                $('#T06-Q02').show();
            });

            $("#T06-Q01-A03").click(function () {
                googAnal('T06-02');
                bc++;
                $('.hidden').hide();
                $('#T06-Q02').show();
            });

            $("#T06-Q01-A04").click(function () {
                googAnal('T06-02');
                d++;
                $('.hidden').hide();
                $('#T06-Q02').show();
            });

            //Question 2

            $("#T06-Q02-A01").click(function () {
                googAnal('T06-03');
                ab++;
                $('.hidden').hide();
                $('#T06-Q03').show();
            });

            $("#T06-Q02-A02").click(function () {
                googAnal('T06-03');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q03').show();
            });

            $("#T06-Q02-A03").click(function () {
                googAnal('T06-03');
                bc++;
                $('.hidden').hide();
                $('#T06-Q03').show();
            });

            $("#T06-Q02-A04").click(function () {
                googAnal('T06-03');
                d++;
                $('.hidden').hide();
                $('#T06-Q03').show();
            });

            //Question 3

            $("#T06-Q03-A01").click(function () {
                googAnal('T06-04');
                ab++;
                $('.hidden').hide();
                $('#T06-Q04').show();
            });

            $("#T06-Q03-A02").click(function () {
                googAnal('T06-04');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q04').show();
            });

            $("#T06-Q03-A03").click(function () {
                googAnal('T06-04');
                bc++;
                $('.hidden').hide();
                $('#T06-Q04').show();
            });

            $("#T06-Q03-A04").click(function () {
                googAnal('T06-04');
                d++;
                $('.hidden').hide();
                $('#T06-Q04').show();
            });

            //Question 4

            $("#T06-Q04-A01").click(function () {
                googAnal('T06-05');
                ab++;
                $('.hidden').hide();
                $('#T06-Q05').show();
            });

            $("#T06-Q04-A02").click(function () {
                googAnal('T06-05');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q05').show();
            });

            //Question 5

            $("#T06-Q05-A01").click(function () {
                googAnal('T06-06');
                ab++;
                $('.hidden').hide();
                $('#T06-Q06').show();
            });

            $("#T06-Q05-A02").click(function () {
                googAnal('T06-06');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q06').show();
            });

            $("#T06-Q05-A03").click(function () {
                googAnal('T06-06');
                bc++;
                $('.hidden').hide();
                $('#T06-Q06').show();
            });

            $("#T06-Q05-A04").click(function () {
                googAnal('T06-06');
                d++;
                $('.hidden').hide();
                $('#T06-Q06').show();
            });

            //Question 6

            $("#T06-Q06-A01").click(function () {
                googAnal('T06-07');
                ab++;
                $('.hidden').hide();
                $('#T06-Q07').show();
            });

            $("#T06-Q06-A02").click(function () {
                googAnal('T06-07');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q07').show();
            });

            $("#T06-Q06-A03").click(function () {
                googAnal('T06-07');
                bc++;
                $('.hidden').hide();
                $('#T06-Q07').show();
            });

            $("#T06-Q06-A04").click(function () {
                googAnal('T06-07');
                d++;
                $('.hidden').hide();
                $('#T06-Q07').show();
            });

            //Question 7

            $("#T06-Q07-A01").click(function () {
                googAnal('T06-08');
                ab++;
                $('.hidden').hide();
                $('#T06-Q08').show();
            });

            $("#T06-Q07-A02").click(function () {
                googAnal('T06-08');
                ab++;
                bc++;
                $('.hidden').hide();
                $('#T06-Q08').show();
            });

            $("#T06-Q07-A03").click(function () {
                googAnal('T06-08');
                bc++;
                $('.hidden').hide();
                $('#T06-Q08').show();
            });

            $("#T06-Q07-A04").click(function () {
                googAnal('T06-08');
                d++;
                $('.hidden').hide();
                $('#T06-Q08').show();
            });

            //Question 8

            $("#T06-Q08-A01").click(function () {
                googAnal('T06-OK');
                ab++;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T06-Q08-A02").click(function () {
                googAnal('T06-OK');
                ab++;
                bc++;
                preguntaCinco = true;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T06-Q08-A03").click(function () {
                googAnal('T06-OK');
                bc++;
                preguntaCinco = true;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T06-Q08-A04").click(function () {
                googAnal('T06-OK');
                d++;
                preguntaCinco = true;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {

                if (preguntaCinco) {
                    $('#result #result-a').remove();
                    $('#result #result-b').remove();
                    $('#result #result-c').remove();
                } else if (ab > bc && ab > d) {
                    $('#result #result-b').remove();
                    $('#result #result-c').remove();
                    $('#result #result-d').remove();
                } else if (bc > ab && bc > d) {
                    $('#result #result-a').remove();
                    $('#result #result-c').remove();
                    $('#result #result-d').remove();
                } else if (d > ab && d > bc) {
                    $('#result #result-a').remove();
                    $('#result #result-b').remove();
                    $('#result #result-d').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };

        },

        init: function () {
            test.item_06.logic();
        }
    };

    /*
     * Test - 07
     */

    test.item_07 = {

        logic: function () {
            var ab = 0;

            $('#T07-Q01').show();

            //Question 1

            $("#T07-Q01-A01").click(function () {
                googAnal('T07-02');
                $('.hidden').hide();
                $('#T07-Q02').show();
            });

            $("#T07-Q01-A02").click(function () {
                googAnal('T07-02');
                ab += 1;
                $('.hidden').hide();

                $('#T07-Q02').show();
            });

            $("#T07-Q01-A03").click(function () {
                googAnal('T07-02');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q02').show();
            });

            $("#T07-Q01-A04").click(function () {
                googAnal('T07-02');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q02').show();
            });

            //Question 2

            $("#T07-Q02-A01").click(function () {
                googAnal('T07-03');
                $('.hidden').hide();
                $('#T07-Q03').show();
            });

            $("#T07-Q02-A02").click(function () {
                googAnal('T07-03');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q03').show();
            });

            $("#T07-Q02-A03").click(function () {
                googAnal('T07-03');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q03').show();
            });

            $("#T07-Q02-A04").click(function () {
                googAnal('T07-03');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q03').show();
            });

            //Question 3

            $("#T07-Q03-A01").click(function () {
                googAnal('T07-04');
                $('.hidden').hide();
                $('#T07-Q04').show();
            });

            $("#T07-Q03-A02").click(function () {
                googAnal('T07-04');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q04').show();
            });

            $("#T07-Q03-A03").click(function () {
                googAnal('T07-04');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q04').show();
            });

            $("#T07-Q03-A04").click(function () {
                googAnal('T07-04');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q04').show();
            });

            //Question 4

            $("#T07-Q04-A01").click(function () {
                googAnal('T07-05');
                $('.hidden').hide();
                $('#T07-Q05').show();
            });

            $("#T07-Q04-A02").click(function () {
                googAnal('T07-05');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q05').show();
            });

            $("#T07-Q04-A03").click(function () {
                googAnal('T07-05');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q05').show();
            });

            $("#T07-Q04-A04").click(function () {
                googAnal('T07-05');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q05').show();
            });

            //Question 5

            $("#T07-Q05-A01").click(function () {
                googAnal('T07-06');
                $('.hidden').hide();
                $('#T07-Q06').show();
            });

            $("#T07-Q05-A02").click(function () {
                googAnal('T07-06');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q06').show();
            });

            $("#T07-Q05-A03").click(function () {
                googAnal('T07-06');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q06').show();
            });

            $("#T07-Q05-A04").click(function () {
                googAnal('T07-06');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q06').show();
            });

            //Question 6

            $("#T07-Q06-A01").click(function () {
                googAnal('T07-07');
                $('.hidden').hide();
                $('#T07-Q07').show();
            });

            $("#T07-Q06-A02").click(function () {
                googAnal('T07-07');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q07').show();
            });

            $("#T07-Q06-A03").click(function () {
                googAnal('T07-07');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q07').show();
            });

            $("#T07-Q06-A04").click(function () {
                googAnal('T07-07');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q07').show();
            });

            //Question 7

            $("#T07-Q07-A01").click(function () {
                googAnal('T07-08');
                $('.hidden').hide();
                $('#T07-Q08').show();
            });

            $("#T07-Q07-A02").click(function () {
                googAnal('T07-08');
                ab += 1;
                $('.hidden').hide();
                $('#T07-Q08').show();
            });

            $("#T07-Q07-A03").click(function () {
                googAnal('T07-08');
                ab += 2;
                $('.hidden').hide();
                $('#T07-Q08').show();
            });

            $("#T07-Q07-A04").click(function () {
                googAnal('T07-08');
                ab += 3;
                $('.hidden').hide();
                $('#T07-Q08').show();
            });

            //Question 8

            $("#T07-Q08-A01").click(function () {
                googAnal('T07-OK');
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T07-Q08-A02").click(function () {
                googAnal('T07-OK');
                ab += 1;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T07-Q08-A03").click(function () {
                googAnal('T07-OK');
                ab += 2;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            $("#T07-Q08-A04").click(function () {
                googAnal('T07-OK');
                ab += 3;
                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                if (ab >= "10") {
                    $('#result #normal').remove();
                } else {
                    $('#result #medico').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');
            };

        },

        init: function () {
            test.item_07.logic();
        }
    };

    /*
     * Test - 08
     */

    test.item_08 = {

        logic: function () {
            var points = 0;

            $('#T08-Q01').show();

            //Question 1

            $("#T08-Q01-submit").click(function (ev) {
                ev.preventDefault();

                var peso = $('#T08-Q01-peso').val();
                var altura = $('#T08-Q01-altura').val();

                if (peso !== '' && altura !== '') {
                    googAnal('T08-02');

                    var imc = peso / Math.pow(altura, 2);
                    if (imc <= 28) points += 1;

                    $('#T08-Q01 .errors').html('');

                    $('.hidden').hide();
                    $('#T08-Q02').show();
                } else {
                    $('#T08-Q01 .errors').html('Requerimos de estos datos para avanzar.');
                }

            });

            //Question 2

            $("#T08-Q02-A01").click(function () {
                googAnal('T08-03');
                $('.hidden').hide();
                $('#T08-Q03').show();
            });

            $("#T08-Q02-A02").click(function () {
                googAnal('T08-03');
                points += 1;
                $('.hidden').hide();
                $('#T08-Q03').show();
            });

            //Question 3

            $("#T08-Q03-A01").click(function () {
                googAnal('T08-04');
                points += 1;
                $('.hidden').hide();
                $('#T08-Q04').show();
            });

            $("#T08-Q03-A02").click(function () {
                googAnal('T08-04');
                $('.hidden').hide();
                $('#T08-Q04').show();
            });

            //Question 4

            $("#T08-Q04-A01").click(function () {
                googAnal('T08-05');
                points += 2;
                $('.hidden').hide();
                $('#T08-Q05').show();
            });

            $("#T08-Q04-A02").click(function () {
                googAnal('T08-05');
                points += 1;
                $('.hidden').hide();
                $('#T08-Q05').show();
            });

            $("#T08-Q04-A03").click(function () {
                googAnal('T08-05');
                $('.hidden').hide();
                $('#T08-Q05').show();
            });

            //Question 5

            $("#T08-Q05-A01").click(function () {
                googAnal('T08-06');
                points += 1;
                $('.hidden').hide();
                $('#T08-Q06').show();
            });

            $("#T08-Q05-A02").click(function () {
                googAnal('T08-06');
                points += 0.75;
                $('.hidden').hide();
                $('#T08-Q06').show();
            });

            $("#T08-Q05-A03").click(function () {
                googAnal('T08-06');
                points += 0.5;
                $('.hidden').hide();
                $('#T08-Q06').show();
            });

            $("#T08-Q05-A04").click(function () {
                googAnal('T08-06');
                points += 0.25;
                $('.hidden').hide();
                $('#T08-Q06').show();
            });

            $("#T08-Q05-A05").click(function () {
                googAnal('T08-06');
                $('.hidden').hide();
                $('#T08-Q06').show();
            });

            //Question 6

            $('#T08-Q06 .multivitaminicos-radio input').click(function () {
                var val = $(this).val();
                if (val === 'Si') {
                    $('.multivitaminicos-input').removeClass('disabled');
                } else {
                    $('.multivitaminicos-input').addClass('disabled');
                }

            });

            $('#T08-Q06 .suplementos-radio input').click(function () {
                var val = $(this).val();
                if (val === 'Si') {
                    $('.suplementos-input').removeClass('disabled');
                } else {
                    $('.suplementos-input').addClass('disabled');
                }
            });

            $("#T08-Q06-submit").click(function () {
                googAnal('T08-07');

                var val01 = $('#multivitaminicos-ingesta').val();
                var val02 = $('#suplementos-ingesta').val();
                var suma = val01 + val02;

                if (suma > 400) {
                    points += 1;
                }

                $('.hidden').hide();
                $('#main').hide();
                check();
            });

            function check() {
                if (points <= 2.25) {
                    $('#result #normal').remove();
                } else {
                    $('#result #medico').remove();
                }
                test.modal.open($('#result').html());
                $('div.amx-content').addClass('result_test');

                $('a.exposicion-solar').click(function () {
                    ga('send', 'event', 'Resultado Test', 'Clic', 'Ir a más información exposición solar');
                });

                $('a.vive-gud').click(function () {
                    ga('send', 'event', 'Resultado Test', 'Clic', 'Ir a Vive Gud');
                });
            };
        },

        init: function () {
            test.item_08.logic();
        }
    };

    test.init();

})(jQuery, window, document);
