/* =========================================================================
 *
 *  MEDIX SITE - JAVASCRIPT
 *
 *
 * ======================================================================== */

(function($, window, document, undefined) {

    /*
     * Fill grid
     * Fills empty spaces on entries grid.
     */

    var fill_grid = {

        fill: function () {

            var count = fill_grid.count_blocks();
            var to_be_filled = 0;

            if ( count < 9 ) { to_be_filled = 9 - count; }

            for (var i = to_be_filled - 1; i >= 0; i--) {

                $('.posts-container').append('<article class="fx"></article>');
            }

            $('.posts-container').append( $('.pager-nav') );
        },

        count_blocks: function () {

            return $('.posts-container article').length;
        },

        init: function () {

            $(document).ready( function () {

                if ( $('.posts-container').length ) {

                    fill_grid.fill();
                }
            });
        }
    };

    /*
     * Calculadora de IMC
     *
     * Detecta si existe el widget de IMC, realiza e imprime
     * el cálculo cuando el valor del input cambia.
     *
     */

    var imc_calculator = {

        $el:     null,
        $weight: null,
        $height: null,
        $result: null,

        bind_events: function () {

            imc_calculator.$el.find('input').each( function () {

                $(this).on( 'keyup', function () {

                    imc_calculator.print_result();
                });
            });
        },

        get_calculation: function () {

            var weight = imc_calculator.$weight.val();
            var height = imc_calculator.$height.val();

            var error = 'Incluya los datos correctamente';
            var result = '';

            if ( weight > 0 && height > 0) {

                result = weight/(height/100*height/100);
                return +( Math.round( result + "e+2")  + "e-2" );

            } else {

                return error;
            }
        },

        print_result: function() {

            var result = imc_calculator.get_calculation();
            imc_calculator.$result.val( result );
        },

        init: function () {

            imc_calculator.$el = $('.imc-calculator');

            if ( imc_calculator.$el.length ) {

                imc_calculator.$weight = $('.imc-calculator .form-weight input');
                imc_calculator.$height = $('.imc-calculator .form-height input');
                imc_calculator.$result = $('.imc-calculator .form-result input');
                imc_calculator.bind_events();
            }
        }
    };

    /*
     * Site
     * Objeto que contiene propiedades y métodos
     * necesarios para correr el sitio.
     */

    var site = {

        init: function() {

            $(document).ready(function() {

                site.sidebar.blocks.init();
                site.layout.init();
                site.pages.init();
                site.product_list.init();

                $(".btn-contacto").click(function(){
                    //Event Analitics
                    ga('send', 'event', 'contacto', 'click', 'contacto');
                    //
                });

                $(".form_btn").click(function(){
                    //Event Analitics
                    ga('send', 'event', 'contacto', 'click', 'right now');
                    //
                });
            });
        }
    };

    /*
     * Site Product list
     * Objeto que inizializa las leyendas de los product List
     */

     site.product_list = {

        init: function(){
            $(document).ready(function(){
                if($('p.product_list_medix')){
                    $('p.product_list_medix').appendTo('div.page-content');
                }
                if($('p.product_list_leyenda')){
                    $('p.product_list_leyenda').appendTo('div.page-content');
                }
            });
        }

     };

    /*
     * Site History
     * Objeto que inizializa la parte de Nuestra Historia y del contenido
     */

     site.histoire = {

        init: function(){
            site.histoire.couleur();
            $('ul#history li').click(function(){
                $('div#content ul li.selected').removeClass('selected');
                $('div#content ul li.'+$(this).text()).addClass('selected');
            });
            $('#next').click(function(){
                if(!$('ul#history li').eq($('ul#history li').length-1).hasClass('selected')){
                    $('ul#history li.selected').eq(0).animate({
                        opacity:0
                    },100, function(){
                        $(this).removeClass('selected');
                        $('ul#history li.selected').eq($('ul#history li.selected').length-1).next().addClass('selected').animate({
                            opacity:1
                        },100, function(){
                            site.histoire.couleur();
                        });
                    });
                }
                site.histoire.couleur();
            });
            $('#prev').click(function(){
                if(!$('ul#history li').eq(0).hasClass('selected')){
                    $('ul#history li.selected').eq($('ul#history li.selected').length-1).animate({
                        opacity:0
                    },100, function(){
                        $(this).removeClass('selected');
                        $('ul#history li.selected').eq(0).prev().addClass('selected').animate({
                            opacity:1
                        },100, function(){
                            site.histoire.couleur();
                        });
                    });
                }
            });
        },

        couleur: function(){

            $('ul#history li.selected').eq(0).css('color','#043A66');
            $('ul#history li.selected').eq(1).css('color','#144793');
            $('ul#history li.selected').eq(2).css('color','#175AAA');
            $('ul#history li.selected').eq(3).css('color','#1368A0');
            $('ul#history li.selected').eq(4).css('color','#298ACC');
            $('ul#history li.selected').eq(5).css('color','#39B4EA');

        }

     };

    /*
     * Site Layout
     * Objeto que inicializa las partes correspondientes
     * al layout del sitio.
     */

    site.layout = {

        init: function () {

            site.layout.header.event_bind();
            site.layout.teaser.init();
        }
    };

    site.redirect = {

        items: function () {

            var pathname = window.location.pathname;

            if ( pathname === '/que-hacemos/' ) {

                window.location = 'http://' + window.location.host + '/que-hacemos/tecnologia-e-investigacion/';

            }
        },

        init: function () {

            site.redirect.items();
        }
    };

    /*
     * Sidebar blocks
     *
     * Intercambia clases en un evento hover,
     * declara un intervalo para simular fx:hover.
     *
     */

    site.sidebar = {};

    site.sidebar.blocks = {

        items: [],

        bind_events: function() {

            $('.sidebar .block .btn').each(function(index) {

                $(this).hover(function() {

                    $(this).addClass('over');

                }, function() {

                    $.each(site.sidebar.blocks.items, function() { $(this).removeClass('over'); });
                });
            });
        },

        init: function () {

            $('.sidebar .block .btn').each(function () { site.sidebar.blocks.items.push(this); });

            site.sidebar.blocks.bind_events();
        }
    };

    /*
     * Home blocks
     *
     * Intercambia clases en un evento hover,
     * declara un intervalo para simular fx:hover.
     *
     */

    site.home_blocks = {

        items: [],

        interval: function() {

            var classname = 'over';
            var intervalObj = null;
            var flag = 0;

            var bindEvents = function() {

                $('.home .nav-banners .banner').each(function(index) {

                    $(this).hover(function() {

                        destroyInterval();
                        $(this).addClass('over');

                    }, function() {

                        $.each(site.home_blocks.items, function() { $(this).removeClass(classname); });
                        createInterval();
                    });
                });
            };

            var createInterval = function() {

                intervalObj = setInterval(function() {

                    if (flag < 2) {
                        toggleClass(flag);
                        flag++;
                    } else {
                        toggleClass(flag);
                        flag = 0;
                    }

                }, 4000);
            };

            var destroyInterval = function() {

                $.each(site.home_blocks.items, function() {
                    $(this).removeClass(classname);
                });
                clearInterval(intervalObj);
            };

            var toggleClass = function(index) {

                $.each(site.home_blocks.items, function() {
                    $(this).removeClass(classname);
                });
                $(site.home_blocks.items[index]).addClass(classname);

                setTimeout(function() {

                    $(site.home_blocks.items[index]).removeClass(classname);
                }, 1250);
            };

            createInterval();
            bindEvents();

        },

        init: function () {
            $(document).ready(function () {

                $('.home .nav-banners .banner').each(function () { site.home_blocks.items.push(this); });

				$('.home .main .youtube-icon a').click(function(){
					$('.youtube_overlay').removeClass('closed');
					$('.youtube_overlay .youtube_overlay_content iframe').height(parseInt($('.youtube_overlay .youtube_overlay_content iframe').width()*9/16));
					return false;
				});
				$('#youtube_overlay_closing').click(function(){
					$('.youtube_overlay').addClass('closed');
					$('iframe#youtube_overlay_video').attr('src', $('iframe#youtube_overlay_video').attr('src'));
					return false;
				});

                site.home_blocks.interval();

            });
        }
    };

    site.layout.header = {

        mode: 'shown',

        hide: function () {

            var header_height = $('.header').height();
            var tl = new TimelineMax();

            if( site.layout.header.mode === 'shown' ) {

                tl.to($('.header'), 0.5, {css: {marginTop: -header_height}}, 'anim');
            }
        },

        show: function () {

            var header_height = $('.header').height();
            var tl = new TimelineMax();

            if( site.layout.header.mode === 'hidden' ) {

                tl.to($('.header'), 0.5, {css: {marginTop: 0}}, 'anim');
            }
        },

        event_bind: function () {

            $('.header').on('header:hidden', function () {

                site.layout.header.hide();
                site.layout.header.mode = 'hidden';
            });

            $('.header').on('header:shown', function () {

                site.layout.header.show();
                site.layout.header.mode = 'shown';
            });

            $(window).on('resize', function () {

                if(site.layout.header.mode === 'hidden') {

                    $('.header').css( 'margin-top', -$('.header').height() );
                }
            });
        }
    };

    site.layout.navigation = {

        load: function () {

            var post_type = $('#ajax-container').find('[data-type]').data('type');
            var post_id = $('#ajax-container').find('[data-id]').data('id');

            var is_front = false;
            var is_blog = false;
            var is_post = false;

            if ( $('body').hasClass('single-post') ) { is_post = true; }
            if ( $('body').hasClass('home') ) { is_front = true; }
            if ( $('body').hasClass('blog') ) { is_blog = true; }

            $.ajax({

                data: {
                    action: 'medix_ajax_menu',
                    post_type: post_type,
                    is_front: is_front,
                    is_post: is_post,
                    is_blog: is_blog,
                    post_id: post_id,
                },

                url: wp_data.ajax_url,
                type: 'POST',

                success: function (data) {

                    $('.header .main-nav').html(data);
                    site.pages.init();
                }
            });
        }
    };

    /*
     * Paged grid for teaser.
     * Toggles 'hidden' class on teaser hover
     */

    site.layout.teaser = {

        $el: $('article.post'),

        bulk_change: function (size) {

            site.layout.teaser.$el = $('article.post');

            var $bg = site.layout.teaser.$el.find('.bg');

            $bg.each( function () {

                var bg_desktop = $(this).data('bgdesktop');
                var bg_mobile = $(this).data('bgmobile');

                switch (size) {

                    case 'mobile':
                        $(this).css('background-image','url(' + bg_mobile + ')');
                        break;
                    case 'desktop':
                        $(this).css('background-image','url(' + bg_desktop + ')');
                        break;
                }
            });
        },

        bind_events: function () {

            site.layout.teaser.$el.each( function () {


                $(this).off();

                $(this).hover( function () {

                    $(this).find('.overlay').removeClass('hidden');
                    $(this).find('.overlay').addClass('animated');

                }, function () {

                    $(this).find('.overlay').addClass('hidden');
                    $(this).find('.overlay').removeClass('animated');
                });
            });

            /*
             * Checks breakpoint change and applies
             * a new bg image depending on the media
             * query in use.
             */

            $(window).on('breakpoint-change', function(e, breakpoint) {

                if(breakpoint === '"site-small"') {
                    site.layout.teaser.bulk_change('mobile');
                }

                if(breakpoint === '"site-medium"' || breakpoint === '"site-large"') {
                    site.layout.teaser.bulk_change('desktop');
                }
            });
        },

        init: function () {

            site.layout.teaser.$el = $('article.post');

            if ( site.layout.teaser.$el.length ) {

                fill_grid.init();
                site.layout.teaser.bind_events();
            }
        }
    };

    site.transitions = {

        all: {

            start: function () {

                $('.main').prepend('<div class="loader-overlay"></div>');

                var $overlay = $('.loader-overlay');
                var tl = new TimelineMax();

                tl.set($overlay, {});
                tl.to($overlay);
                tl.play();
            },

            init: function () {

            }
        },

        home: {

            init: function () {

            }
        }
    };

    site.pages = {

        all: function () {
            site.transitions.all.init();
            imc_calculator.init();
        },

        home: function () {

            $('.header').trigger('header:hidden');
            site.transitions.home.init();
            site.home_blocks.init();
        },

        not_front: function () {

            $('.header').trigger('header:shown');
        },

        init: function () {

            site.pages.all();

            if( $('body').hasClass('home') ) { site.pages.home(); }
            if( !$('body').hasClass('home') ) { site.pages.not_front(); }

			//Check menus
			if($('nav.main-nav ul li').eq(0).text() == ''){
				$('nav.main-nav ul').addClass('vide');
			}

            //if( $('body').hasClass('page-id-432') ) { site.forms.farmacovigi.init(); }
            //if( $('body').hasClass('page-id-359') ) { site.forms.contacto.init(); }
            if( $('body').hasClass('page-id-429') ) { site.forms.prensa.init(); }
            if( $('body').hasClass('page-id-57') ) { site.histoire.init(); }
            if( $('body').hasClass('page-id-359') ) { site.forms.contactform.init(); }
			if( $('body').hasClass('page-id-426') ) { site.forms.hazcarreraform.init(); }
            if( $('body').hasClass('page-id-432') ) { site.forms.farmacoform.init(); }
            if( $('body').hasClass('page-id-769') ) { site.responsabilidad.init();}
            if( $('body').hasClass('page-id-596') ) { site.forms.inversionists.init(); }
            if( $('body').hasClass('page-id-1629') ) { site.forms.gracias.init();}
            if( $('body').hasClass('postid-1097') ) { site.sobrepeso.init(); }
            if( $('body').hasClass('postid-945') ) { site.situacionmexico.init(); }
            if( $('body').hasClass('postid-938') ) { site.imc_wiki.init();}
            if( $('body').hasClass('postid-1637') ) { site.antiobesidad.init();}
            /*IF Taxonomy Tema*/
            if( $('body').hasClass('tax-tema') ) { site.taxonomy_tema.init();}
            if( $('form#buscar_syo').length>0){ site.syobuscar.init();};
        }
    };

    site.forms = {

        group_elements: function ( args ) {

            var wrapper = document.createElement('div');
            wrapper.className = args.group_class;

            $.each( args.elements, function () {

                $(wrapper).append($(this));
            });

            args.form.append( $(wrapper) );
            return;
        },

        /*
         * Strip labels according to
         * Ninja Forms structure.
         */

        strip_labels: function ($el) {

            $el.find('label').each( function () {

                $(this).find('span').remove();
                $(this).append(' *');

                $(this).wrapInner('<span></span>');
            });
        },

        bind_events: function ($form) {

            $form.find('.text-wrap').each( function () {

                $(this).find('input').each( function () {

                    $(this).on('change', function () {

                        if( $(this).val() && $(this).val() !== '' ) {

                            $(this).closest('.text-wrap').addClass('input-filled');

                        } else {

                            $(this).closest('.text-wrap').removeClass('input-filled');
                        }
                    });
                });
            });
        }
    };

    /*
     * Step by step form, structure:
     * .steps_wrapper > .steps_content > .step * n
     */

     site.forms.only_characters = {
        init:function(){
            $('.only_letters').bind('keyup blur',function(){
                var node = $(this);
                node.val(node.val().replace(/[^A-Za-zÁáÉéÍíÓóÚúÑñ ]/g,'') ); }
            );
            $('.only_numbers').bind('keyup blur',function(){
                var node = $(this);
                node.val(node.val().replace(/[^0-9-+., ()]/g,'') ); }
            );
        }
     };

    site.forms.slider = {

        $controls: null,

        $form: null,

        pointers : [],

        current_position: 1,

        prev_position: 1,

        form_steps: 0,

        /*
         * Modifies wrapper width and gets the position
         * of the steps setting them in a pointers variable.
         */

        modify_wrapper_width: function () {

            var $form = site.forms.slider.$form;
            var step_count = $form.find('.step').length;
            var container_width = $form.find('.steps_wrapper').width();

            site.forms.slider.form_steps = step_count;

            $form.find('.steps_content').css('width', ( container_width * step_count ) + 'px');

            $form.find('.step').each( function () {

                $(this).css('width', container_width + 'px');
            });

            for (var i = 0; i < step_count; i++) {

                site.forms.slider.pointers[i] = i * container_width;
            }
        },

        modify_wrapper_height: function () {

            var $form = site.forms.slider.$form;

            var current_el = $form.find('.step-0' + Math.abs(site.forms.slider.current_position + 1 ) );
            var current_el_height = current_el.height();

            $form.find('.steps_wrapper').css('height', current_el_height + 30 + 'px');
        },

        setup_controls: function () {

            /*
             * Declare slider controls
             */

            var $next = site.forms.slider.$controls.find('.next');
            var $prev = site.forms.slider.$controls.find('.prev');
            var step_control = [];

            var $form = site.forms.slider.$form;
            var $container = $form.find('.steps_content');

            function reset_active () {

                $.each(step_control, function () { $(this).removeClass('active'); });
            }

            function set_active () {

                var active_item = '.pager-item.item-' + site.forms.slider.current_position;
                site.forms.slider.$controls.find(active_item).addClass('active');
            }

            site.forms.slider.$controls.find('.pager-item').each( function (index) {

                if( !$(this).hasClass('prev') && !$(this).hasClass('next') ) {

                    step_control[index-1] = $(this);
                }
            });

            /*
             * Event binding
             */

            $next.on('click', function () {

                if ( site.forms.slider.current_position < ( site.forms.slider.form_steps - 1 ) ) {

                    reset_active();

                    site.forms.slider.prev_position = site.forms.slider.current_position;
                    site.forms.slider.current_position = site.forms.slider.current_position + 1;

                    set_active();
                    site.forms.slider.modify_wrapper_height();

                    TweenMax.to( $container, 0.5, { x: -( site.forms.slider.pointers[ site.forms.slider.current_position ] ) });
                }
            });

            $prev.on('click', function () {

                if ( site.forms.slider.current_position > 0 ) {

                    reset_active();

                    site.forms.slider.prev_position = site.forms.slider.current_position;
                    site.forms.slider.current_position = site.forms.slider.current_position - 1;

                    set_active();
                    site.forms.slider.modify_wrapper_height();

                    TweenMax.to( $container, 0.5, { x: -( site.forms.slider.pointers[ site.forms.slider.current_position ] ) });
                }
            });

            $.each(step_control, function () {

                $(this).on('click', function () {

                    var slide = Number.parseInt( $(this).data('slide') );

                    reset_active();

                    site.forms.slider.prev_position = site.forms.slider.current_position;
                    site.forms.slider.current_position = slide - 1;

                    set_active();
                    site.forms.slider.modify_wrapper_height();

                    TweenMax.to( $container, 0.5, { x: -( site.forms.slider.pointers[ site.forms.slider.current_position ] ) });
                });
            });
        },

        bind_events: function () {

            $(window).on('resize', function () {

                var $form = site.forms.slider.$form;
                var $container = $form.find('.steps_content');

                site.forms.slider.modify_wrapper_width();

                TweenMax.to( $container, 0.5, { x: -( site.forms.slider.pointers[ site.forms.slider.current_position ] ) });
            });
        },

        init: function ( $form, $controls ) {

            site.forms.slider.$form = $form;
            site.forms.slider.$controls = $controls;

            site.forms.slider.setup_controls();
            site.forms.slider.bind_events();

            site.forms.slider.modify_wrapper_width();
            site.forms.slider.modify_wrapper_height();

            site.forms.slider.$form.find('select').each( function () {

                $(this).dropkick({ mobile: true });

            });
        }
    };

    site.forms.contactform = {

        init: function(){
            if($('form').find('.only_letters').length > 0 || $('form').find('.only_numbers').length > 0){
                site.forms.only_characters.init();
            }
            $('#ninja_forms_form_5 .text-wrap input.ninja-forms-field, #ninja_forms_form_5 .list-dropdown-wrap select, #ninja_forms_form_5 .number-wrap input, #ninja_forms_form_5 .textarea-wrap textarea').each(function(){
                if($(this).val() || $(this).val() != '' || $(this).val() !== ''){
                    var that = $(this).parent();
                    var label = $(this).siblings('label');
                    if(!that.hasClass('focussed')){
                        that.addClass('focussed');
                        label.animate({
                            'top':'-30px',
                            'line-height':'0'
                        },300);
                    }
                }
            });
			$('#ninja_forms_form_5 .text-wrap input, #ninja_forms_form_5 .list-dropdown-wrap select, #ninja_forms_form_5 .number-wrap input, #ninja_forms_form_5 .textarea-wrap textarea').focus(function(){
                var that = $(this).parent();
                var label = $(this).siblings('label');
                if(!that.hasClass('focussed')){
                    that.addClass('focussed');
                    label.animate({
                        'top':'-30px',
                        'line-height':'0'
                    },300);
                }
            });
			$('#ninja_forms_form_5 .text-wrap input, #ninja_forms_form_5 .list-dropdown-wrap select, #ninja_forms_form_5 .number-wrap input, #ninja_forms_form_5 .textarea-wrap textarea').focusout(function(){
                var that = $(this).parent();
                var label = $(this).siblings('label');
                if ($(this).val()==''){
                    label.animate({
                        'top':'0px',
                        'line-height':'30px'
                    },300);
                    that.removeClass('focussed');
                }
            });
			//TERMINOS Y CONDICIONES CLICK
			$('label#ninja_forms_field_22_label a').click(function(){
				$('footer .menu #menu-item-156 a').trigger('click');
				return false;
			});
			//AVISO PRIVACIDAD CLICK
			$('label#ninja_forms_field_23_label a').click(function(){
				$('footer .menu #menu-item-124 a').trigger('click');
				return false;
			});
        }
    };

	site.forms.hazcarreraform = {

		init: function(){
            if($('form').find('.only_letters').length > 0 || $('form').find('.only_numbers').length > 0){
                site.forms.only_characters.init();
            }
            $('.ninja-forms-form .text-wrap input.ninja-forms-field,.ninja-forms-form .textarea-wrap textarea,.ninja-forms-form .number-wrap input').each(function(){
                if($(this).val()){
                    var that = $(this).parent().parent();
                    var label = $(this).parent().siblings('label');
                    if(!that.hasClass('focussed')){
                        that.addClass('focussed');
                        label.animate({
                            'top':'-30px',
                            'line-height':'0'
                        },300);
                    }
                }
            });
            $('.ninja-forms-form .text-wrap input,.ninja-forms-form .textarea-wrap textarea,.ninja-forms-form .number-wrap input').focus(function(){
                var that = $(this).parent().parent();
                var label = $(this).parent().siblings('label');
                if(!that.hasClass('focussed')){
                    that.addClass('focussed');
                    label.animate({
                        'top':'-30px',
                        'line-height':'0'
                    },300);
                }
            });
            $('.ninja-forms-form .text-wrap input,.ninja-forms-form .textarea-wrap textarea,.ninja-forms-form .number-wrap input').focusout(function(){
                var that = $(this).parent().parent();
                var label = $(this).parent().siblings('label');
                if ($(this).val()==''){
                    label.animate({
                        'top':'0px',
                        'line-height':'30px'
                    },300);
                    that.removeClass('focussed');
                }
            });
			$('input#ninja_forms_field_92').change(function(){
				if($(this).parent().siblings('p.fichier').length<1){
					$(this).parent().parent().append('<p class="fichier" style="color:#3F95D2;"></p>');
				}
				$(this).parent().siblings('p.fichier').html($(this).val());
				$(this).parent().parent().find('label').addClass('changer');
			});
			//TERMINOS Y CONDICIONES CLICK
			var terms = $('#ninja_forms_field_86 .wpcf7-list-item-label').text().split('Términos y Condiciones');
			terms = terms[0] + '<a href="#" id="terms">Términos y Condiciones</a>' + terms[1];
			$('#ninja_forms_field_86 .wpcf7-list-item-label').html(terms);
			$('#ninja_forms_field_86 .wpcf7-list-item-label a#terms').click(function(){
				$('footer .menu #menu-item-156 a').trigger('click');
				return false;
			});
			//AVISO PRIVACIDAD CLICK
			var avis = $('#ninja_forms_field_87 .wpcf7-list-item-label').text().split('Aviso de Privacidad');
			avis = avis[0] + '<a href="#" id="avis">Aviso de Privacidad</a>' + avis[1];
			$('#ninja_forms_field_87 .wpcf7-list-item-label').html(avis);
			$('#ninja_forms_field_87 .wpcf7-list-item-label a#avis').click(function(){
				$('footer .menu #menu-item-124 a').trigger('click');
				return false;
			});
        }

	};

    site.forms.farmacoform = {

		date_select_mecanique: function(){
			$('form div.datepicker-wrap select.datepicker_mod').change(function(){
				var nDate = $(this).parent().parent().find('select.datepicker_day').val()+'/'+$(this).parent().parent().find('select.datepicker_month').val()+'/'+$(this).parent().parent().find('select.datepicker_year').val();
				if($(this).parent().parent().find('select.datepicker_month').val() != '' && $(this).parent().parent().find('select.datepicker_year').val() != ''){
					$(this).parent().parent().find('input.ninja-forms-field').val(nDate);
					$(this).parent().parent().addClass('focussed');
				}else{
					if($(this).parent().parent().hasClass('focussed')){
						$(this).parent().parent().find('input.ninja-forms-field').val('');
						$(this).parent().parent().removeClass('focussed');
					}
				}
			});
		},

        date_to_selects: function(){
			var maxYear = new Date().getFullYear(),
				minYear = maxYear - 60,
				day = '<div class="datepicker_arrow"><select class="datepicker_day datepicker_mod">',
				month = '<div class="datepicker_arrow"><select class="datepicker_month datepicker_mod">',
				year = '<div class="datepicker_arrow"><select class="datepicker_year datepicker_mod">';
			for(var i=0; i<=31; i++){
				if( i === 0){
					day += '<option value="" selected>Día</option>';
				}else{
					day += '<option value="'+i+'">'+i+'</option>';
				}
			}
			day += '</select></div>';
			for(var i=0; i<=12; i++){
				if( i === 0){
					month += '<option value="" selected>Mes</option>';
				}else{
					month += '<option value="'+i+'">'+i+'</option>';
				}
			}
			month += '</select></div>';
			for(var i=maxYear+1; i>=minYear; i--){
				if( i === maxYear+1){
					year += '<option value="" selected>Año</option>';
				}else{
					year += '<option value="'+i+'">'+i+'</option>';
				}
			}
			year += '</select></div>';
			$('input.datepicker').each(function(){
				$(this).attr('type','hidden');
				//$(this).parent().addClass('focussed');
				$(this).siblings('label').css('top','-40px');
				$(this).parent().html($(this).parent().html()+day+month+year);
			});
			site.forms.farmacoform.date_select_mecanique();
        },

        groups_elements:function(){
            var i;
            for (i = 0; i < 4; i++) {
                if(i==0){
                    $('.field-wrap').eq(i).prepend('<h3>1.Notificador</h3>');
                }
                $('.field-wrap').eq(i).addClass('group1 farmaco_visible');
            }
            for (i = 4; i < 9; i++) {
                if(i==4){
                    $('.field-wrap').eq(i).prepend('<h3>2.Paciente</h3>');
                }
                $('.field-wrap').eq(i).addClass('group2');
            }
            for (i = 9; i < 17; i++) {
                if(i==9){
                    $('.field-wrap').eq(i).prepend('<h3>3.Sospecha de reacción adversa</h3>');
                }
                $('.field-wrap').eq(i).addClass('group3');
            }
            for (i = 17; i < $('.field-wrap').length; i++) {
                if(i==17){
                    $('.field-wrap').eq(i).prepend('<h3>4.Información del medicamento sospechoso</h3>');
                }
                $('.field-wrap').eq(i).addClass('group4');
            }
        },

        navegacion:function(){
            $('div.form-pager ul li.pager-item').click(function(){
                if($(this).hasClass('prev') || $(this).hasClass('next')){
                    if($(this).hasClass('prev')){
                        if(!$('div.form-pager ul li.active').prev().hasClass('prev')){
                            $('div.form-pager ul li.active').prev().trigger('click');
                        }
                    }
                    if($(this).hasClass('next')){
                        if(!$('div.form-pager ul li.active').next().hasClass('next')){
                            $('div.form-pager ul li.active').next().trigger('click');
                        }
                    }
                }else{
					var allGood = true,
						prev = false;
					$('.farmaco_visible .ninja-forms-req').each(function(){
						if($(this).val() == ''){
							allGood = false;
						}
					});
					if($(this).index() < $('div.form-pager ul li.active').index()){
						if(allGood == false){
							if(!$('div.form-pager ul li.active').hasClass('notcomplete')){
								$('div.form-pager ul li.active').addClass('notcomplete');
							}
							if($('div.form-pager ul li.active').hasClass('thatsgood')){
								$('div.form-pager ul li.active').removeClass('thatsgood');
							}
						}
						prev = true;
						allGood = true;
					}
					if(allGood == true){
						if(prev == false){
							if($('div.form-pager ul li.active').hasClass('notcomplete')){
								$('div.form-pager ul li.active').removeClass('notcomplete');
							}
							$('div.form-pager ul li.active').addClass('thatsgood');
						}
						if($('p#msg_error_required').length>0){
							$('p#msg_error_required').remove();
						}
						$('div.form-pager ul li.active').removeClass('active');
						$(this).addClass('active');
						$('.field-wrap.farmaco_visible').each(function(){
							$(this).removeClass('farmaco_visible');
						});
						if($(this).attr('data-slide') === 4 || $(this).attr('data-slide') === '4'){
							$('div.prev_next_button_container').addClass('hidden');
						}else{
							if($('div.prev_next_button_container').hasClass('hidden')){
								$('div.prev_next_button_container').removeClass('hidden');
							}
						}
						$('.field-wrap.group'+$(this).attr('data-slide')).each(function(){
							$(this).addClass('farmaco_visible');
						});
					}else{
						if($('p#msg_error_required').length === 0){
							$('.form-pager').append('<p id="msg_error_required">Debe llenar los campos requeridos.</p>');
						}
					}
                }
            });
        },

        init: function(){
            if($('form').find('.only_letters').length > 0 || $('form').find('.only_numbers').length > 0){
                site.forms.only_characters.init();
            }
			$('div.field-wrap.country-wrap').removeClass('country-wrap').addClass('list-dropdown-wrap');
			$('form#ninja_forms_form_8').submit(function(event){
				var allGood = true;
				$('form#ninja_forms_form_8 .ninja-forms-req').each(function(){
					if($(this).val() == ''){
						allGood = false;
					}
				});
				if(allGood==false){
					event.preventDefault();
					$('.forma-farmacovigilancia .form-pager ul li.pager-item').each(function(){
						if(!$(this).hasClass('next') && !$(this).hasClass('prev') && !$(this).hasClass('thatsgood') && !$(this).hasClass('notcomplete')){
							$(this).addClass('notcomplete');
						}
					});
					if($('p#msg_error_required').length === 0){
						$('.form-pager').append('<p id="msg_error_required">Debe llenar los campos requeridos.</p>');
					}
					$('input#ninja_forms_field_38').val('ENVIAR');
				}
			});
            $('div.ninja-forms-required-items').appendTo('form#ninja_forms_form_8');

            site.forms.farmacoform.date_to_selects();
            site.forms.farmacoform.groups_elements();
            site.forms.farmacoform.navegacion();

			$('#ninja_forms_form_8_all_fields_wrap').append('<div class="prev_next_button_container"><a href="#" class="prev_next_button prev_button">Siguiente</a></div>');
			$('.prev_next_button').click(function(){
				$('div.form-pager ul li.pager-item.next').trigger('click');
				return false;
			});

		 	$('#ninja_forms_form_8 .text-wrap input.ninja-forms-field, #ninja_forms_form_8 .list-dropdown-wrap select, #ninja_forms_form_8 .number-wrap input.ninja-forms-field, #ninja_forms_form_8 .textarea-wrap textarea').each(function(){
                if($(this).val() || $(this).val() != '' || $(this).val() !== ''){
                    var that = $(this).parent();
                    var label = $(this).siblings('label');
                    if(!that.hasClass('focussed')){
                        that.addClass('focussed');
                        label.animate({
                            'top':'-40px',
                            'line-height':'normal'
                        },300);
                    }
                }
            });
			$('#ninja_forms_form_8 .text-wrap input, #ninja_forms_form_8 .list-dropdown-wrap select, #ninja_forms_form_8 .number-wrap input, #ninja_forms_form_8 .textarea-wrap textarea').focus(function(){
                var that = $(this).parent();
                var label = $(this).siblings('label');
				if(!that.hasClass('focussed')){
					that.addClass('focussed');
					label.animate({
						'top':'-40px',
						'line-height':'normal'
					},300);
				}
            });
			$('#ninja_forms_form_8 .text-wrap input, #ninja_forms_form_8 .list-dropdown-wrap select, #ninja_forms_form_8 .number-wrap input, #ninja_forms_form_8 .textarea-wrap textarea').focusout(function(){
                var that = $(this).parent();
                var label = $(this).siblings('label');
                if ($(this).val()==''){
                    label.animate({
                        'top':'0px',
                        'line-height':'normal'
                    },300);
                    that.removeClass('focussed');
                }
            });
			//TERMINOS Y CONDICIONES CLICK
			$('label#ninja_forms_field_65_label a').click(function(){
				$('footer .menu #menu-item-156 a').trigger('click');
				return false;
			});
			//AVISO PRIVACIDAD CLICK
			$('label#ninja_forms_field_64_label a').click(function(){
				$('footer .menu #menu-item-124 a').trigger('click');
				return false;
			});
        }
    };

    site.forms.gracias = {
        init: function(){
            var get_params = window.location.search.substring(1);
            get_params = get_params.split('&');
            get_params = get_params[0].split('=');
            if (get_params[0] !== 'origin'){
                window.location = '/';
            }else{
                switch(get_params[1]){
                    case 'farmacovigi':
                        ga('send', 'event', 'Formulario Enviado - Farmacovigilancia', 'enviado');
                        break;
                    case 'contacto':
                        ga('send', 'event', 'Formulario Enviado - Contacto', 'enviado');
                        break;
                    case 'carrera':
                        ga('send', 'event', 'Formulario Enviado - Haz Carrera', 'enviado');
                        break;
                    default:
                        window.location = '/';
                        break;
                }
            }
        }
    };

    site.forms.inversionists = {

        init : function(){
            $('div.form-part.usr input').focus(function(){
                $(this).parent().addClass('focussed');
            });
            $('div.form-part.usr input').focusout(function(){
                $(this).parent().removeClass('focussed');
            });
        }

    };

    site.forms.contacto = {

        group_form_fields: function () {

            var $form = $('.ninja-forms-form');

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-01',
                elements:       [
                                    $('#ninja_forms_field_10_div_wrap'),
                                    $('#ninja_forms_field_11_div_wrap'),
                                    $('#ninja_forms_field_12_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-02',
                elements:       [
                                    $('#ninja_forms_field_14_div_wrap'),
                                    $('#ninja_forms_field_15_div_wrap'),
                                    $('#ninja_forms_field_16_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-03',
                elements:       [
                                    $('#ninja_forms_field_17_div_wrap'),
                                    $('#ninja_forms_field_18_div_wrap'),
                                    $('#ninja_forms_field_19_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-04',
                elements:       [
                                    $('#ninja_forms_field_21_div_wrap'),
                                    $('#ninja_forms_field_22_div_wrap'),
                                    $('#ninja_forms_field_23_div_wrap'),
                                    $('#ninja_forms_field_24_div_wrap'),
                                    $('#ninja_forms_field_13_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form: $form,
                group_class: 'steps_content',
                elements:   [
                                $('.step-01'),
                                $('.step-02'),
                                $('.step-03'),
                                $('.step-04')
                            ]
            });

            site.forms.group_elements({

                form: $form,
                group_class: 'steps_wrapper',
                elements:   [ $('.steps_content') ]
            });

        },

        strip_form_labels: function () { site.forms.strip_labels( $('.ninja-forms-form') ); },

        bind_events: function ($form) { site.forms.bind_events($form); },

        init: function () {

            site.forms.contacto.group_form_fields();
            site.forms.contacto.strip_form_labels();
            site.forms.contacto.bind_events( $('.ninja-forms-form') );
            site.forms.slider.init( $('.ninja-forms-form'), $('.form-pager') );
        }
    };

    site.forms.prensa = {

        group_form_fields: function () {

            var $form = $('.ninja-forms-form');

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-01',
                elements:       [
                                    $('#ninja_forms_field_25_div_wrap'),
                                    $('#ninja_forms_field_26_div_wrap'),
                                    $('#ninja_forms_field_27_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-02',
                elements:       [
                                    $('#ninja_forms_field_28_div_wrap'),
                                    $('#ninja_forms_field_29_div_wrap'),
                                    $('#ninja_forms_field_30_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-03',
                elements:       [
                                    $('#ninja_forms_field_31_div_wrap'),
                                    $('#ninja_forms_field_32_div_wrap'),
                                    $('#ninja_forms_field_33_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form:           $form,
                group_class:    'step step-04',
                elements:       [
                                    $('#ninja_forms_field_34_div_wrap'),
                                    $('#ninja_forms_field_35_div_wrap'),
                                    $('#ninja_forms_field_36_div_wrap'),
                                    $('#ninja_forms_field_37_div_wrap')
                                ]
            });

            site.forms.group_elements({

                form: $form,
                group_class: 'steps_content',
                elements:   [
                                $('.step-01'),
                                $('.step-02'),
                                $('.step-03'),
                                $('.step-04')
                            ]
            });

            site.forms.group_elements({

                form: $form,
                group_class: 'steps_wrapper',
                elements:   [ $('.steps_content') ]
            });

        },

        strip_form_labels: function () { site.forms.strip_labels( $('.ninja-forms-form') ); },

        bind_events: function ($form) { site.forms.bind_events($form); },

        init: function () {

            site.forms.prensa.group_form_fields();
            site.forms.prensa.strip_form_labels();
            site.forms.prensa.bind_events( $('.ninja-forms-form') );
            site.forms.slider.init( $('.ninja-forms-form'), $('.form-pager') );
        }
    };

    site.forms.farmacovigi = {

        //NEW

        init: function(){
            site.forms.farmacovigi.select_to_ul();
            site.forms.groups_elements();
        },

        select_to_ul: function(){
            $('div.list-dropdown-wrap select').each(function(){
                var id_slct = $(this).attr('id'),
                    name_slct = $(this).attr('name'),
                    class_slct = $(this).attr('class'),
                    ul_slct = $('<ul class="'+class_slct+'"></ul>');
                $(this).children('option').each(function(){
                    ul_slct.append('<li data-val="'+$(this).attr('value')+'">'+$(this).text()+'</li>');
                });
                $('<input type="hidden" id="'+id_slct+'" name="'+name_slct+'" value="" />').insertAfter($(this));
                ul_slct.insertAfter($(this));
                $(this).remove();
            });
        },

        groups_elements: function(){
            for (var i = 0; i < 4; i++){
                $('form#ninja_forms_form_8 div.ninja-forms-all-fields-wrap .field-wrap').eq(i).addClass('group1');
            }

            for (var i = 4; i < 12; i++){
                $('form#ninja_forms_form_8 div.ninja-forms-all-fields-wrap .field-wrap').eq(i).addClass('group2');
            }

            for (var i = 0; i < 4; i++){
                $('form#ninja_forms_form_8 div.ninja-forms-all-fields-wrap .field-wrap').eq(i).addClass('group1');
            }

            for (var i = 0; i < 4; i++){
                $('form#ninja_forms_form_8 div.ninja-forms-all-fields-wrap .field-wrap').eq(i).addClass('group1');
            }

        }
    };

	site.responsabilidad = {
		init: function(){
			$('ul#responsabilidad-first li h2').click(function(){
				$(this).parent().toggleClass('actif');
			});
		}
	};

    site.ajax = {

        set_classes: function () {

            var $el = $('#ajax-container').find('[data-body]');
            var classes = $el.attr('data-body');

            $('body').attr('class', '');
            $('body').attr('class', classes);
        },

        set_title: function () {

            var title = $('#ajax-container').find('[data-title]').data(title);
            document.title = title.title;
        },

        init: function() {

            $.pjax.defaults.timeout = 5000;

            $(document).pjax('a:not([data-expjax])', '#ajax-container');

            $(document).on('pjax:end', function(data) {

                site.ajax.set_title();
                site.ajax.set_classes();
                site.layout.navigation.load();

                site.redirect.init();
                site.layout.init();
            });
        }
    };

    site.sobrepeso = {

        init: function() {
            $('ul#list-div-ul li a').click(function(){
                $('div.list-div.selected').removeClass('selected');
                $('div#'+$(this).parent().attr('data-id')).addClass('selected');
                return false;
            });
        }
    };



    site.situacionmexico = {

        init: function() {
            $('ul#links li a').click(function(){
                $('div.list-div.selected').removeClass('selected');
                $('div#'+$(this).attr('data-id')).addClass('selected');
                return false;
            });
        }
    };

    site.taxonomy_tema = {

        init:function(){
            var themeName = $('h1').text();
            themeName = themeName.replace(/tema: /g,'');
            $('#ajax-container').prepend('<p id="breadcrumbs"><span xmlns:v="http://rdf.data-vocabulary.org/#"><span typeof="v:Breadcrumb"><a href="/" rel="v:url" property="v:title">Inicio</a> » <span rel="v:child" typeof="v:Breadcrumb"><a href="/sobrepeso-y-obesidad/" rel="v:url nofollow" property="v:title">Sobrepeso, Obesidad y Calidad de vida</a> » <span class="breadcrumb_last">'+themeName+'</span></span></span></span></p>');
			$('#ajax-container').html('<article class="page"><div class="page-container">'+$('#ajax-container').html()+'</div></article>');
            $('<form role="search" method="get" id="buscar_syo" class="searchform" action="/"><input name="s" id="query" placeholder="Buscar por palabra" type="text"><ul id="syo_select_theme"><li data-val="" class="syo_selected">Filtrar por tema</li><li data-val="sobrepeso">Sobrepeso</li><li data-val="obesidad">Obesidad</li><li data-val="enfermedades-relacionadas">Enfermedades relacionadas</li><li data-val="prevencion">Prevención</li><li data-val="tratamiento">Tratamiento</li><li data-val="alimentacion">Alimentación</li><li data-val="actividad-fisica">Actividad fisíca</li><li data-val="manejo-psicologico">Manejo psicológico</li><li data-val="indice-masa-corporal">Indice de Masa Corporal</li><li data-val="impacto-economico">Impacto Economico</li></ul><input id="post_type" name="post_type" value="sobrepeso-obesidad" type="hidden"><input type="hidden" id="tema" name="tema" value="" /><input id="send_syo" value="Buscar" type="submit"></form>').insertAfter('h1');
        }

    },

    site.imc_wiki = {

        init: function(){
            $('form#form_peso_corporal').submit(function(e){
                e.preventDefault();
                var weight = parseFloat($(this).children('#peso').val().replace(',', '.'));
                var height = $(this).children('#talla').val();
                var error = 'Error';
                var conseil = 'Incluya los datos correctamente';
				var gender = $(this).children('#sexo').val();
				var tallaBaja = false;
				if((gender === 'Mujer' && height < 150) || (gender === 'Hombre' && height < 160)){
					tallaBaja = true;
				}
                var result = site.imc_wiki.calculateIMC(height,weight,error);
				if(tallaBaja === false){
					if (result < 18.5) {
						conseil = "<h3>Bajo peso (< 18.5)</h3><p>Tu estado de salud puede ser deficiente, acude con tu médico para una valoración más detallada y se determine la causa de tu bajo peso.</p>";
					}
					if (result >= 18.5 && result < 25 ) {
						conseil = "<h3>Peso normal (18.5 a 24.9)</h3><p>Presentas un peso saludable. Sin embargo, es conveniente  que acudas con tu médico para una valoración completa y se descarten factores de riesgo cardiovasculares, además de seguir estrategias (estilo de vida saludable) que eviten el aumento de peso.</p>";
					}
					if (result >= 25 && result <30 ) {
						conseil = "<h3>Sobrepeso (25.0 a 29.9)</h3><p>Acude con tu médico. Presentas un peso mayor en relación a tu altura. Puede ser que ese exceso de peso sea en realidad de grasa, por lo que aumenta el riesgo de padecer ciertas enfermedades crónicas.</p>";
					}
					if (result >= 30 && result <35 ) {
						conseil = "<h3>Obesidad grado I (30.0 a 34.9)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
					if (result >= 35 && result <40 ) {
						conseil = "<h3>Obesidad grado II (35.0 a 39.9)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
					if (result >= 40) {
						conseil = "<h3>Obesidad grado III (≥40)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
				}else{
					if (result < 18.5) {
						conseil = "<h3>Bajo peso (< 18.5)</h3><p>Tu estado de salud puede ser deficiente, acude con tu médico para una valoración más detallada y se determine la causa de tu bajo peso.</p>";
					}
					if (result >= 18.5 && result < 23 ) {
						conseil = "<h3>Peso normal (18.5 a 22.9)</h3><p>Presentas un peso saludable. Sin embargo, es conveniente  que acudas con tu médico para una valoración completa y se descarten factores de riesgo cardiovasculares, además de seguir estrategias (estilo de vida saludable) que eviten el aumento de peso.</p>";
					}
					if (result >= 23 && result < 25 ) {
						conseil = "<h3>Sobrepeso (23.0 a 24.9)</h3><p>Acude con tu médico. Presentas un peso mayor en relación a tu altura. Puede ser que ese exceso de peso sea en realidad de grasa, por lo que aumenta el riesgo de padecer ciertas enfermedades crónicas.</p>";
					}
					if (result >= 25 && result < 30 ) {
						conseil = "<h3>Obesidad grado I (25.0 a 29.9)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
					if (result >= 30 && result < 35 ) {
						conseil = "<h3>Obesidad grado II (30.0 a 34.9)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
					if (result >= 35) {
						conseil = "<h3>Obesidad grado III (≥35)</h3><p>Es importante que acudas con tu médico, para una evaluación más detallada de tu salud. La obesidad es una enfermedad  crónica que requiere tratamiento para prevenir complicaciones y mejorar la calidad de vida.</p>";
					}
				}
                $('div#result_calcul_peso h2 span').html(result);
                $('div#recommendation_results span').html(conseil);
            });
        },
        calculateIMC : function(h,w,e){
            if ( w > 0 && h > 0) {
                var result = w/(h/100*h/100);
                return +( Math.round( result + "e+2")  + "e-2" );
            } else {
                return e;
            }
        }
    };

    site.antiobesidad = {
        init:function(){
            $('div#accordeon section h2').click(function(){
                $(this).parent().toggleClass('actived');
            });
        }
    };

    site.syobuscar = {

        init: function(){
            $('div.main').click(function(evt){
                if($('ul#syo_select_theme').hasClass('opened')){
                    $('ul#syo_select_theme').removeClass('opened');
                }
            });
            $('ul#syo_select_theme').click(function(event){
                event.stopPropagation();
                if($(this).hasClass('opened')){
                    $(this).removeClass('opened');
                }else{
                    $(this).addClass('opened');
                    $('ul#syo_select_theme li').click(function(){
                        //$('input#theme_syo').val($(this).attr('data-val'));
                        $('li.syo_selected').removeClass('syo_selected');
                        $(this).addClass('syo_selected');
						$('input#tema').val($(this).attr('data-val'));
                    });
                }
            });
            $('form#buscar_syo').submit(function(event){
				if( $('li.syo_selected').attr('data-val')=='' && $('input#query').val() == ''){
					event.preventDefault();
				}else{
				ga('send', 'event', 'Buscar', 'click');
					if($('input#query').val() == ''){
						event.preventDefault();
						if($('li.syo_selected').attr('data-val') == 'indice-masa-corporal'){
							window.location = "/sobrepeso-obesidad/peso-corporal/";
						}else{
							window.location = "/temas/"+$('li.syo_selected').attr('data-val')+"/";
						}
					}
					if($('input#tema').val() == ''){
						$('input#tema').remove();
					}
				}
            });
			site.syobuscar.previous();
        },
		previous: function(){
			if($('#articulos_recomendados').length > 0){
				$('#articulos_recomendados').eq(0).prepend('<a href="#" id="previous_syo">Regresar</a>');
			}
			$('#previous_syo').click(function(){
				window.location = document.referrer;
				return false;
			});
		}

    };

    site.init();

})(jQuery, window, document);


var mq_listener = ( function (parent, $) {

    var MediaQueryListener = function () {
        this.afterElement = window.getComputedStyle ? window.getComputedStyle(document.body, ':after') : false;
        this.currentBreakpoint = '';
        this.lastBreakpoint = '';
        this.init();
    };

    MediaQueryListener.prototype = {

        init: function () {

            var self = this;
            if (!self.afterElement) { return; }
            self._resizeListener();
        },

        _resizeListener: function () {

            var self = this;

            $(window).on('resize orientationchange load', function () {

                self.currentBreakpoint = self.afterElement.getPropertyValue('content');

                if (self.curentBreakpoint !== self.lastBreakpoint) {

                    $(window).trigger('breakpoint-change', self.currentBreakpoint);
                    self.lastBreakpoint = self.currentBreakpoint;
                }
            });
        }
    };

    parent.mediaqueryListener = parent.mediaqueryListener || new MediaQueryListener();

    return parent;

})( mq_listener || {}, jQuery );
